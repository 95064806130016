<template>
<div class="row">
    <div class="col-md-6">
        <seccion-datos :titulo="companiastitulo" :botonGuardar="false" class="card card-warning">
            <div class="row">
                <div class="col-md-7">
                    <div class="input-group">
                        <select class="form-control" v-model="seleccionado">
                            <option v-for="compania in listado_companias" :key="compania.id" :value="compania.id">{{ compania.nombre }}
                                <p v-if="compania.prefijo">({{ compania.prefijo }})</p> 
                            </option>
                        </select>
                    </div>
                </div>
                        <div class="col-md-1">
                            <button class="btn btn-primary" @click="añadircompañia(seleccionado)">{{ $t('general.anadir') }}</button>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-md-12">
                            <table class="table table-sm table-striped">
                                <tbody>
                                    <tr v-for="oper in operario_compañia" :key="oper.idcompañia">
                                        <td> {{oper.nombre}}</td>
                                        <td align="right" >
                                            <div  class="col-xs-4">
                                                <button  type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminar(oper.idcompañia)">{{ $t('general.eliminar') }}</button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> 
        </seccion-datos>
    </div>
</div>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id','operario'],
    data() {
        return {
            listado_companias: [],
            seleccionado: 'Selecciona una compañia',
            operario_compañia: '',
            companiastitulo: "Compañías",
        }
    },
    methods: {
        async cargarDatosCompañias() {
            /* GET  modulos/pwgsapi/inde x.php/companias/simple */
            const api = new PwgsApi;
            const datos = await api.get('companias/simple',);
            this.listado_companias = datos.datos;
            this.companiastitulo = this.$t("general.companias");
        },
        async cargarDatosOperario() {
            /* GET  modulos/pwgsapi/index.php/operarios/:id/companias */
            const api = new PwgsApi;
            const datos = await api.get('operarios/' + this.id + '/companias');
            this.operario_compañia = datos.datos;
        },
        async eliminar(idcompañia) {  
            /* DELETE  modulos/pwgsapi/index.php/operarios/:id/companias/:id_compania */
            const api = new PwgsApi;
            await api.delete('operarios/' + this.id + '/companias/' + idcompañia);
            this.cargarDatosOperario();

        },
        async añadircompañia(id) {
            /* POST  modulos/pwgsapi/index.php/operarios/:id/companias */
            let repetido = 0;
            if (this.operario_compañia.length > 1) {
                for (let opers of this.operario_compañia) {
                    if (id == opers.idcompañia) {
                        repetido = repetido + 1;
                    }
                }
            }
            if (repetido < 1) {
              //  const subidadatos = { id_companyia: id };
                const api = new PwgsApi;
                await api.post('operarios/' + this.id + '/companias/', id);
                this.cargarDatosOperario();
            }
            
        }
    },
    watch: {
        id() {
            this.cargarDatosCompañias();
            this.cargarDatosOperario();
        }
    },
    mounted() {
        this.cargarDatosCompañias();
        this.cargarDatosOperario();
    }
}
</script>